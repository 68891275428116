@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Your CSS file or style block */
.ant-modal-body {
  max-height: 70vh; /* Set the maximum height for the scrollbar to appear */
  overflow-y: auto; /* Enable vertical scrollbar */
  scrollbar-width: thin; /* Set the width of the scrollbar on Firefox */
  scrollbar-color: darkgrey lightgrey; /* Set the color of the scrollbar on Firefox */
}

/* For Webkit browsers (Chrome, Safari) */
.ant-modal-body::-webkit-scrollbar {
  width: 4px ; /* Set the width of the scrollbar */
}

.ant-modal-body::-webkit-scrollbar-thumb {
  background-color: rgb(226, 15, 15); /* Set the color of the thumb */
}

.ant-modal-body::-webkit-scrollbar-track {
  background-color: rgb(255, 31, 31); /* Set the color of the track */
}

.animation-effect{
  transition: all ease 0.9s;
}



@media (min-width: 500px) { 
  .login-bg{
    background-image: url('../src/Assets/login_bg.jpg');
    width: 100%;
    height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
   }
 }

